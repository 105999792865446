import React from 'react';
import moment from 'moment';
import setKey from 'lodash/set';
import isUndefined from 'lodash/isUndefined';

import Types from '../../modules/types';

import DownloadConfirmation from '../../components/Potvrda';

const { helpers: { getTranslation } } = Types;

export default ['ActionWithFormModal', {
  name: 'download-potvrda',
  label: `${getTranslation('confirmationPDF')}`,
  title: `${getTranslation('confirmationPDF')}`,
  modalProps: {
    centered: true,
    closable: true,
    wrapClassName: 'potvrda-modal',
  },
  skip: [props => !props.data.isVaccine],
  fields: [...[
    ['FieldTitle', { title: 'Vlasnik' }],
    ...[
      ['owner.name', 'Ime'],
      ['owner.address', 'Adresa'],
      ['owner.zipCode', 'Postanski broj'],
      ['owner.city', 'Grad'],
      ['owner.phoneNumber', 'Telefon'],
      ['owner.uniqueIdentificationNumber', 'JMBG'],
    ].map(([name, label]) => ['FieldText', {
      name,
      label,
      col: { xs: 24, lg: 12 },
    }]),
    ['FieldTitle', { title: 'Ljubimac' }],
    ...[
      ['animal.name', 'Ime'],
      ['animal.breed', 'Rasa'],
      ['animal.color', 'Boja'],
    ].map(([name, label]) => ['FieldText', {
      name,
      label,
      col: { xs: 24, lg: 12 },
    }]),
    ['FieldSelect', {
      name: 'animal.sex',
      label: `${getTranslation('sex')}:`,
      choices: Types.SEX.map(sex => ({
        value: sex,
        label: getTranslation(sex),
      })),
      col: { xs: 24, lg: 12 },
    }],
    ['FieldDatePicker', {
      name: 'animal.dateOfBirth',
      label: `${getTranslation('date_of_birth')}:`,
      placeholder: null,
      prepareValueForInput: value => (value ? moment(value) : null),
      format: ['DD.MM.YYYY.'],
      col: { xs: 24, lg: 12 },
    }],
    ['FieldText', {
      name: 'animal.idNumber',
      label: `${getTranslation('id_number')}`,
      col: { xs: 24, lg: 12 },
    }],
    ...[
      ['animal.passportNumber', 'Broj pasoša'],
      ['animal.passportIssueAuthority', 'Pasoš izdat od'],
    ].map(([name, label]) => ['FieldText', {
      name,
      label,
      col: { xs: 24, lg: 12 },
    }]),
    ['FieldDatePicker', {
      name: 'animal.passportIssueDate',
      label: `${getTranslation('passport_issue_date')}:`,
      placeholder: null,
      prepareValueForInput: value => (value ? moment(value) : null),
      format: ['DD.MM.YYYY.'],
    }],

    ['FieldTitle', { title: 'Vakcina' }],

    ...[
      ['vaccine.name', 'Ime'],
      ['vaccine.illness', 'Zaštita od bolesti'],
      ['vaccine.serialNumber', 'Serijski broj'],
      ['vaccine.price', 'Cena', true],
      ['vaccine.priceChip', 'Cena Čipa', true],
      ['vaccine.confirmationNo', 'Broj Potvrde', false],
    ].map(([name, label, number = false, full = false]) => ['FieldText', {
      name,
      label,
      disabled: [() => true],
      col: full ? 24 : { xs: 24, lg: 12 },
      type: number ? 'number' : 'text',
    }]),

    ['FieldTitle', {
      title: 'Ostalo',
    }],

    ['FieldText', {
      name: 'createdBy.name',
      label: 'Napravio',
    }],

    ['FieldDatePicker', {
      name: 'createdAt',
      label: `${getTranslation('created_at')}:`,
      placeholder: null,
      prepareValueForInput: value => (value ? moment(value) : null),
      format: ['DD.MM.YYYY.'],
      col: { xs: 24, lg: 12 },
    }],
    ['FieldText', {
      name: 'amount',
      label: `${getTranslation('total')}`,
      addonAfter: Types.CURRENCY,
      getInitialValue: (field, props) => (
        props.data?.vaccine?.price + props.data?.vaccine?.priceChip
      ),
      type: 'number',
      col: { xs: 24, lg: 12 },
    }],
  ].map(field => [field[0], {
    ...field[1],
  }])],
  actions: [
    ['Action', {
      name: 'download-sticker',
      title: 'Skini',
      renderAction: (props, action) => {
        const json = {};
        (props.fields || []).forEach((field) => {
          if (!field.isVirtual || field.isVirtual({ ...props, ...field })) {
            return;
          }
          const key = field.name;
          const value = props.form.getFieldValue(field.name);
          if (!isUndefined(value) && (field.sendEmpty || value !== '')) {
            setKey(json, key, value);
          }
        });

        const data = {
          ...props.data,
          ...json,
        };

        return (
          <DownloadConfirmation
            intervention={data}
            fileName={`${moment(props.data.createdAt).format('DD.MM.YYYY')
              }_${props.data.animal.name
              }_vakcinacija.pdf`}
          >
            {
              ({ loading }) => (
                loading
                  ? action.renderAction({
                    ...props,
                    title: 'Loading...',
                    disabled: true,
                  })
                  : action.renderAction(props)
              )
            }
          </DownloadConfirmation>
        );
      },
    }],
  ],
}];
