import React from 'react';

import Admin from 'hive-admin';
import OriginalActionSave from 'hive-admin/src/components/ActionSave';

import { useHotkeys } from 'react-hotkeys-hook';

function ActionWrapper({ children, callback }) {
  useHotkeys(
    'ctrl+s, meta+s',
    (event) => {
      event.preventDefault();
      callback();
    },
    {
      enableOnContentEditable: true,
      enableOnFormTags: ['INPUT', 'TEXTAREA', 'SELECT'],
    },
    [callback],
  );
  return children;
}

class ActionSave extends OriginalActionSave {
  static config = {
    ...OriginalActionSave.config,
    ghost: true,
    icon: 'save',
    renderAction: (props, instance) => (
      <ActionWrapper callback={instance.handleClick}>
        {instance.renderAction(props)}
      </ActionWrapper>
    ),
  }
}

Admin.addToLibrary(
  'ActionSave',
  config => ActionSave.create(config),
);
