import * as React from 'react';

function SvgCat(props) {
  return (
    <svg height="1em" viewBox="0 0 512.023 512.023" width="1em" {...props}>
      <path d="M296.19 309.532h-15c-5.061 0-9.178-4.117-9.178-9.178v-2.151l9.064-13.111c6.203-8.972-.219-21.218-11.126-21.218h-27.877c-10.907 0-17.328 12.246-11.126 21.218l9.064 13.111v2.151c0 5.061-4.117 9.178-9.178 9.178h-15c-8.836 0-16 7.164-16 16s7.164 16 16 16c15.898 0 27.14 1.48 40.178-8.629 12.982 10.065 24.384 8.629 40.178 8.629 8.836 0 16-7.164 16-16s-7.163-16-15.999-16z" />
      <circle cx={188.745} cy={220.206} r={19.841} />
      <circle cx={323.279} cy={220.206} r={19.841} />
      <path d="M511.581 230.764c-2.037-8.599-10.658-13.918-19.257-11.882l-43.209 10.234c-1.61-24.418-8.594-47.878-20.452-69.231V33.994c0-28.031-32.091-43.97-54.428-27.054l-78.796 59.676c-25.652-4.837-52.583-4.954-78.855 0L137.788 6.939C115.443-9.983 83.36 5.973 83.36 33.994v125.892c-11.859 21.353-18.842 44.813-20.452 69.231l-43.209-10.234c-8.6-2.035-17.22 3.283-19.257 11.882-2.037 8.599 3.283 17.22 11.882 19.257l51.707 12.247c2.388 17.606 7.613 34.602 15.462 50.545l-38.753 7.861c-8.66 1.757-14.257 10.201-12.5 18.861 1.539 7.587 8.21 12.822 15.664 12.822 2.307 0-1.25.58 53.671-10.56 7.496 9.736 16.081 18.815 25.72 27.141-31.683 46.629-27.636 86.738-27.636 127.085 0 8.836 7.164 16 16 16h288.705c8.836 0 16-7.164 16-16 0-40.936 4.014-80.506-27.636-127.085 9.638-8.325 18.223-17.404 25.72-27.141l50.473 10.238c8.675 1.759 17.108-3.855 18.861-12.5 1.757-8.66-3.84-17.104-12.5-18.861l-38.753-7.861c7.849-15.944 13.075-32.94 15.462-50.545l51.707-12.247c8.6-2.038 13.92-10.659 11.883-19.258zM393.556 32.449a1.925 1.925 0 013.107 1.544v84.431c-18.374-17.774-39.756-31.363-62.934-40.666zm-277.121-.19c1.631-.811-3.467-3.974 61.859 45.5-23.163 9.297-44.549 22.881-62.934 40.666 0-93.094-.461-85.403 1.075-86.166zm267.929 447.764H127.659c0-25.045-2.474-56.303 21.508-92.25 64.407 39.184 149.242 39.208 213.689 0 23.393 35.065 21.508 64.866 21.508 92.25zm15.368-173.862l-35.163-7.132c-8.66-1.757-17.104 3.84-18.861 12.5s3.84 17.104 12.5 18.861l20.282 4.114c-64.604 67.546-180.382 67.513-244.955 0l20.282-4.114c8.66-1.757 14.257-10.201 12.5-18.861s-10.2-14.255-18.861-12.5l-35.163 7.132c-6.396-11.182-11.214-23.205-14.209-35.828l38.465 9.11c8.646 2.046 17.231-3.329 19.257-11.882 2.037-8.599-3.283-17.22-11.882-19.257l-49.34-11.686c2.035-78.432 73.666-141.674 161.429-141.674s159.394 63.242 161.429 141.674l-49.34 11.686c-8.599 2.037-13.918 10.658-11.882 19.257 2.032 8.577 10.636 13.923 19.257 11.882l38.465-9.11c-2.996 12.623-7.814 24.646-14.21 35.828z" />
    </svg>
  );
}

export default SvgCat;
