// /* global window */
import React from 'react';
import { Link } from 'hive-admin/node_modules/react-router-dom';

import { UsergroupAddOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';

import Admin from 'hive-admin';

import {
  getLinkWithSearchFields,
  OwnerAnimalLink,
} from '../../components/Links';

import Types from '../../modules/types';

import {
  ACTIONS,
  filterPagination,
  filterSortByName,
  filterSearch,
} from '../common';

const { helpers: { getTranslation, decimalize } } = Types;

const isNotEditActivity = ['condition.check', {
  path1: 'activity',
  op: '!==',
  value2: 'edit',
}];

export default Admin.compileFromLibrary(['GroupResource', {
  title: getTranslation('owners'),
  // icon: 'usergroup-add',
  icon: UsergroupAddOutlined,
  path: '/owners',
  redirect: [['redirect.unauthorized']],
  archiveConfig: {
    createButtonLabel: getTranslation('create_new_item'),
    icon: UsergroupAddOutlined,
    title: getTranslation('owners'),
    label: getTranslation('owners'),
    filters: [
      filterSearch,
      filterSortByName,
      filterPagination,
    ],
    columns: [
      {
        path: 'name',
        title: getTranslation('name'),
        render: (record, column, props) => (
          <div
            style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          >
            <OwnerAnimalLink
              location={props.location}
              subject={record}
              pathname="/animals"
            >
              <span
                style={{
                  color: (
                    record.balance < 0
                    ? 'red'
                    : record.balance > 0
                    ? 'green'
                    : ''
                  ),
                }}
              >
                {record.name}
              </span>
            </OwnerAnimalLink>
          </div>
        ),
      },
      {
        path: 'phoneNumber',
        title: getTranslation('phone_number'),
      },
      {
        path: 'balance',
        title: getTranslation('balance'),
        render: record => `${
          decimalize((record.balance || 0)) // TODO check if this is correct
        } ${Types.CURRENCY}`,
      },
    ],
  },
  singleConfig: {
    hidden: true,
    alias: '/owners',
    backButtonPaths: ['/owners'],
    backButtonLabel: getTranslation('close'),
    headerTitle: getTranslation('create'),
  },
  singleCreateConfig: {
    title: getTranslation('owners'),
    actions: [ACTIONS.CREATE],
  },
  singleEditConfig: {
    title: getTranslation('owners'),
    actions: [
      ACTIONS.SAVE,
      ACTIONS.DELETE,
      [() => ({
        IS_ACTION: true,
        section: 'bottom',
        render: props => (
          <Button type="primary" key={`${Math.random()}`.slice(2)}>
            <Link
              to={getLinkWithSearchFields(
                null,
                [{
                  name: 'owner',
                  value: props.data._id,
                  replace: true,
                }],
                { pathname: '/animals/create' },
              )}
            >
              {getTranslation('new_pet')}
            </Link>
          </Button>
        ),
      }), {}],
      [() => ({
        IS_ACTION: true,
        section: 'bottom',
        render: props => (
          <Button type="primary" key={`${Math.random()}`.slice(2)}>
            <Link
              to={getLinkWithSearchFields(
                null,
                [{
                  name: 'owner',
                  value: props.data._id,
                  replace: true,
                }],
                { pathname: '/animals' },
              )}
            >
              {getTranslation('see_pets')}
            </Link>
          </Button>
        ),
      }), {}],
      [() => ({
        IS_ACTION: true,
        section: 'bottom',
        render: props => (
          <Button type="primary" key={`${Math.random()}`.slice(2)}>
            <Link
              to={getLinkWithSearchFields(
                null,
                [{
                  name: 'owner',
                  value: props.data._id,
                  replace: true,
                }],
                { pathname: '/interventions' },
              )}
            >
              {getTranslation('see_interventions')}
            </Link>
          </Button>
        ),
      }), {}],
      [() => ({
        IS_ACTION: true,
        section: 'bottom',
        render: props => (
          <Button type="primary" key={`${Math.random()}`.slice(2)}>
            <Link
              to={getLinkWithSearchFields(
                null,
                [{
                  name: 'owner',
                  value: props.data._id,
                  replace: true,
                }, {
                  name: 'amount',
                  value: Math.abs(props.data.balance),
                  replace: true,
                }, {
                  name: 'name',
                  value: JSON.stringify(getTranslation('debt_payment')),
                  replace: true,
                }, {
                  name: 'debtPayment',
                  value: true,
                  replace: true,
                }, {
                  name: 'allowCreate',
                  value: true,
                }],
                { pathname: '/transactions/create' },
              )}
            >
              {getTranslation('debt_payout')}
            </Link>
          </Button>
        ),
      }), {}],
      // [() => ({
      //   IS_ACTION: true,
      //   section: 'bottom',
      //   render: props => (
      //     <Button type="primary" key={`${Math.random()}`.slice(2)}>
      //       <Link
      //         to={getLinkWithSearchFields(
      //           null,
      //           [{
      //             name: 'owner',
      //             value: props.data._id,
      //             replace: true,
      //           }],
      //           { pathname: '/transactions' },
      //         )}
      //       >
      //         {getTranslation('see_transactions')}
      //       </Link>
      //     </Button>
      //   ),
      // }), {}],
    ],
  },
  fields: [
    ['FieldText', {
      name: 'name',
      label: null,
      prefix: `${getTranslation('name')}:`,
      col: 24,
    }],
    ['FieldText', {
      name: 'email',
      label: null,
      prefix: `${getTranslation('email')}:`,
      autoComplete: 'new-email',
      rules: [['validation.isEmail']],
      col: 24,
    }],
    ['FieldText', {
      name: 'phoneNumber',
      label: null,
      prefix: `${getTranslation('phone_number')}:`,
      autoComplete: 'new-phoneNumber',
      col: 24,
    }],
    ['FieldText', {
      name: 'address',
      label: null,
      prefix: `${getTranslation('address')}:`,
      autoComplete: 'new-address',
      col: 24,
    }],
    ['FieldText', {
      name: 'zipCode',
      label: null,
      prefix: `${getTranslation('zipCode')}:`,
      autoComplete: 'new-address',
      // col: { lg: 8, md: 24 },
    }],
    ['FieldText', {
      name: 'city',
      label: null,
      prefix: `${getTranslation('city')}:`,
      autoComplete: 'new-address',
      // col: { lg: 16, md: 24 },
    }],
    ['FieldText', {
      name: 'uniqueIdentificationNumber',
      label: null,
      prefix: `${getTranslation('unique_identification_number')}:`,
    }],
    ['FieldText', {
      name: 'balance',
      label: null,
      prefix: `${getTranslation('balance')}:`,
      addonAfter: Types.CURRENCY,
      prepareValueForInput: value => `${decimalize(value || 0)}`,
      disabled: [() => true],
      virtual: [() => true],
      skip: [isNotEditActivity],
    }],
  ],
}]).pages;
