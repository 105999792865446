import isFinite from 'lodash/isFinite';

import Types from '../modules/types';

const { helpers: { getTranslation } } = Types;

export function cloneItem(
  item,
  col = null,
  isAvailable = null,
  extras = {},
) {
  return [
    item[0],
    Object.assign(
      { ...item[1] },
      col ? { col: isFinite(col) ? { xs: 24, lg: col } : col } : {},
      isAvailable ? { isAvailable } : {},
      { ...extras },
    ),
  ];
}

export const ACTIONS = {
  CREATE: ['ActionCreate', {
    title: getTranslation('create'),
    messageWorking: `${getTranslation('creating')}...`,
    messageSuccess: `${getTranslation('created')}!`,
    disabled: [
      ['condition.isLoading'],
      ['condition.and', [
        ['not', ['condition.anyFieldTouched']],
        // eslint-disable-next-line max-len
        ({ searchParams }) => !searchParams.allowCreate,
        ({ searchParams, data }) => !searchParams.duplicate || !data,
      ]],
      ['condition.anyFieldHasError'],
    ],
  }],
  SAVE: ['ActionSave', {
    title: getTranslation('save'),
    messageWorking: `${getTranslation('saving')}...`,
    messageSuccess: `${getTranslation('saved')}!`,
  }],
  DELETE: ['ActionDelete', {
    title: getTranslation('delete'),
    messageWorking: `${getTranslation('deleting')}...`,
    messageSuccess: `${getTranslation('deleted')}!`,
    popconfirm: true,
  }],
  DELETE_ADMIN: ['ActionDelete', {
    title: getTranslation('delete'),
    messageWorking: `${getTranslation('deleting')}...`,
    messageSuccess: `${getTranslation('deleted')}!`,
    popconfirm: true,
    skip: [
      ['condition.checkViewer', 'role', '!==', 'ADMIN'],
    ],
  }],
};

export const filterSearch = ['FilterRegexSearch', {
  id: 'search',
  placeholder: getTranslation('search'),
  paths: ['name'],
  caseInsensitive: true,
  section: 'top',
  col: 12,
}];

export const filterSortByName = ['FilterHidden', {
  id: 'sort',
  build: builder => builder.add(
    'sort',
    { name: 1 },
  ),
}];

export const filterPagination = ['FilterPagination', {
  id: 'pagination',
  section: 'bottom',
}];
