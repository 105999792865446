/* eslint-disable no-unused-vars */
import isString from 'lodash/isString';

import React from 'react';
import styled from 'styled-components';
import Icon from 'antd/lib/icon';

import Admin from 'hive-admin/src/components/Admin';
import FieldReact from 'hive-admin/src/components/FieldReact';

const ToggleIconWrapper = styled.div`
  display: inline-block;
  transition: transform 250ms ease-out;
  font-size: 26px;
  i, svg, path {
    color: ${({ $active, theme }) => (
    $active
      ? theme.less.primaryColor
      : theme.less.textColor
  )} !important;
    fill: ${({ $active, theme }) => (
    $active
      ? theme.less.primaryColor
      : theme.less.textColor
  )} !important;
    stroke: ${({ $active, theme }) => (
    $active
      ? theme.less.primaryColor
      : theme.less.textColor
  )} !important;
  }
`;

const ToggleIcon = styled(Icon)`
  transform-origin: center;
  font-size: 64%;
  transform: scale(1, 0.8);
  display: flex;
  path {
    stroke-width: 60px;
  }
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;

const Title = styled.h1`
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin: 0px;
  padding: 0px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: ${({ $size }) => (
    $size === 'small'
      ? '18px'
      : $size === 'medium'
        ? '22px'
        : '26px'
  )};
  /* line-height: 100%; */
  > .field-title-text {
    display: inline-block;
    font-weight: 600;
    color: ${({ theme }) => theme.less.textColor};
    opacity: 0.85;
    padding-left: 0px;
  }
  > .field-title-annotation {
    /* color: ${({ theme }) => theme.less.textColor}; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 100%;
    opacity: 0.7;
    font-weight: 400;
    font-size: 13px;
  }
`;

const Subtitle = styled.h2`
  margin: 0px;
  padding: 0px;
  /* margin-top: 10px; */
  /* margin-bottom: -10px; */
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  line-height: 120%;
  > .field-subtitle-text {
    color: ${({ theme }) => theme.less.textColor};
    opacity: 0.7;
    padding-left: 0px;
  }
`;

Admin.addToLibrary(
  'FieldTitle',
  ({
    size = 'large',
    icon = null,
    getIcon = (iconInput, props) => iconInput,
    title = null,
    getTitle = (titleInput, props) => titleInput,
    annotation = null,
    getAnnotation = (annotationInput, props) => annotationInput,
    subtitle = null,
    getSubtitle = (subtitleInput, props) => subtitleInput,
    wrapperStyle = undefined,
    getWrapperStyle = (wrapperStyleInput, props) => wrapperStyleInput,
    wrapperProps = undefined,
    getWrapperProps = (wrapperPropsInput, props) => wrapperPropsInput,
    style = undefined,
    titleStyle = undefined,
    annotationStyle = undefined,
    subtitleStyle = undefined,
    textStyle = undefined,
    subtitleTextStyle = undefined,
    ...config
  } = {}) => FieldReact.create({
    // name: name || (
    //   typeof title === 'string'
    //   ? title
    //   : `${Math.floor(Math.random() * 1000000)}`
    // ),
    label: null,
    virtual: true,
    renderContent: (props) => {
      const finalIcon = getIcon(icon, props);
      const finalTitle = getTitle(title, props);
      const finalAnnotation = getAnnotation(annotation, props);
      const finalSubtitle = getSubtitle(subtitle, props);
      const wrapperPropsFinal = getWrapperProps(wrapperProps, props) || {};
      titleStyle = titleStyle || style;
      if (!finalIcon && !finalTitle && !finalSubtitle) {
        return null;
      }
      return (
        <Wrapper
          style={getWrapperStyle(wrapperStyle, props)}
          {...wrapperPropsFinal}
        >
          {
            (finalIcon || finalTitle)
              ? (
                <Title style={titleStyle} $size={size}>
                  {
                    isString(finalIcon)
                      ? <Icon type={finalIcon} />
                      : finalIcon
                  }
                  {
                    finalTitle
                      ? (
                        <>
                          {' '}
                          <span
                            style={textStyle}
                            className="field-title-text"
                          >
                            {finalTitle}
                          </span>
                        </>
                      )
                      : null
                  }
                  {
                    finalAnnotation
                      ? (
                        <>
                          {' '}
                          <span
                            style={annotationStyle}
                            className="field-title-annotation"
                          >
                            {finalAnnotation}
                          </span>
                        </>
                      )
                      : null
                  }
                </Title>
              )
              : null
          }
          {
            finalSubtitle
              ? (
                <Subtitle style={subtitleStyle}>
                  <span
                    style={subtitleTextStyle}
                    className="field-subtitle-text"
                  >
                    {finalSubtitle}
                  </span>
                </Subtitle>
              )
              : null
          }
        </Wrapper>
      );
    },
    ...config,
  }),
);

Admin.addToLibrary(
  'FieldTitleToggle',
  ({
    // icon,
    ...config
    // eslint-disable-next-line arrow-body-style
  }) => {
    //
    return Admin.compileFromLibrary(['FieldTitle', {
      virtual: [],
      ...config,
      getIcon: (inputIcon, props) => {
        const { value } = props;
        return (
          <ToggleIconWrapper
            $active={!!value}
            style={{
              transform: (
                !value
                  ? 'scaleY(1)'
                  : 'scaleY(-1)'
              ),
            }}
          >
            <ToggleIcon type="down" />
          </ToggleIconWrapper>
        );
      },
      getWrapperStyle: (wrapperStyleInput, props) => ({
        ...(wrapperStyleInput || {}),
        cursor: 'pointer',
      }),
      getWrapperProps: (inputWrapperProps, props) => ({
        ...(inputWrapperProps || {}),
        onClick: () => {
          props.onChange(!props.value);
        },
      }),
    }]);
  }
);
