import React from 'react';
import { Link } from 'hive-admin/node_modules/react-router-dom';
import Popover from 'antd/lib/popover';
import moment from 'moment';

import Types from '../../modules/types';

import {
  // AnimalInterventionLink,
  DateLink,
  OwnerInterventionLink,
  getLinkWithSearchFields,
} from '../../components/Links';

const { LANG_KEY: langKey, helpers: { getTranslation, decimalize } } = Types;

const columnAnimal = {
  path: 'animal.name',
  title: getTranslation('animal', langKey),
  render: record => (
    !record.animal.alive
      ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Popover
            title={null}
            content={(
              <div>
                <span>
                  {`${getTranslation('show_all', langKey)
                    } ${getTranslation('interventions', langKey).toLowerCase()
                    } `}
                </span>
                <Link
                  to={getLinkWithSearchFields(
                    null,
                    [{
                      name: 'animal',
                      value: record.animal._id,
                      replace: true,
                    }],
                    { pathname: '/interventions' },
                  )}
                >
                  {getTranslation('for_this_pet', langKey)}
                </Link>
              </div>
            )}
          >
            <Link
              to={`/animals/${record.animal._id}`}
              style={{ textDecoration: 'line-through' }}
            >
              {record.animal.name}
            </Link>
          </Popover>
          <Popover content={getTranslation('deceased', langKey)}>
            <span
              style={{ fontFamily: 'Arial, sans, serif', fontSize: '15px' }}
            >
              &#10013;
            </span>
          </Popover>
        </div>
      )
      : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Popover
            title={null}
            content={(
              <div>
                <span>
                  {`${getTranslation('show_all', langKey)
                    } ${getTranslation('interventions', langKey).toLowerCase()
                    } `}
                </span>
                <Link
                  to={getLinkWithSearchFields(
                    null,
                    [{
                      name: 'animal',
                      value: record.animal._id,
                      replace: true,
                    }],
                    { pathname: '/interventions' },
                  )}
                >
                  {getTranslation('for_this_pet', langKey)}
                </Link>
              </div>
            )}
          >
            <Link
              to={`/animals/${record.animal._id}`}
            >
              {record.animal.name}
            </Link>
          </Popover>
          {record.animal.allergies.length > 0 && (
            <Popover
              content={`${getTranslation('allergies', langKey)}: ${
                record.animal.allergies.reduce((agr, { allergy }, i) => {
                  if (i === (record.animal.allergies.length - 1)) {
                    agr += `${allergy || ''}`;
                  } else {
                    agr += `${allergy || ''}, `;
                  }
                  return agr;
                },
                '')
              }`}
            >
              <span
                style={{ color: '#ff4d4f', fontWeight: 600 }}
              >
                A
              </span>
            </Popover>
          )}
        </div>
      )
  ),
};

export default [
  {
    path: 'name',
    title: getTranslation('name'),
    link: '/interventions/:_id',
  },
  {
    path: 'owner',
    title: getTranslation('owner'),
    render: (record, column, props) => (
      <OwnerInterventionLink
        location={props.location}
        subject={record.owner}
        pathname="/interventions"
      />
    ),
  },
  columnAnimal,
  {
    path: 'owner.phoneNumber',
    title: getTranslation('phone_number'),
    render: (record) => record.owner.phoneNumber,
  },
  {
    path: 'amount',
    title: getTranslation('amount'),
    render: record => `${decimalize(record.amount)} ${Types.CURRENCY}`,
  },
  {
    path: 'paid',
    title: getTranslation('paid'),
    render: record => `${decimalize(record.paid)} ${Types.CURRENCY}`,
  },
  {
    path: 'createdAt',
    title: getTranslation('created_at'),
    render: (record, column, props) => (
      <DateLink
        location={props.location}
        date={record.createdAt}
      >
        {moment(record.createdAt).format('DD.MM.YYYY.')}
      </DateLink>
    ),
  },
  {
    path: 'createdBy',
    title: getTranslation('created_by'),
    render: (record) => (record?.createdBy?.name) || '-',
  },
];
