import getKey from 'lodash/get';

import Admin from 'hive-admin';

import Types from '../modules/types';

import {
  ACTIONS,
  filterPagination,
  filterSortByName,
  filterSearch,
  cloneItem,
} from './common';

const { helpers: { getTranslation } } = Types;

const skipPassword = ({ data, viewer, activity }) => (
  false // activity === 'create'
  || (
    activity === 'edit'
    && getKey(data, '_id', 0) !== getKey(viewer, '_id', 1)
  )
);

const isNotCreateActivity = ['condition.check', {
  path1: 'activity',
  op: '!==',
  value2: 'create',
}];

export default Admin.compileFromLibrary(['GroupResource', {
  path: '/users',
  icon: 'user',
  title: getTranslation('users'),
  archiveConfig: {
    createButtonLabel: getTranslation('create_new_item'),
    icon: 'user',
    title: getTranslation('users'),
    label: getTranslation('users'),
    skip: [
      ['condition.checkViewer', 'role', '!==', 'ADMIN'],
    ],
  },
  singleConfig: {
    hidden: true,
    alias: '/users',
    backButtonPaths: ['/users'],
    headerTitle: getTranslation('create'),
  },
  singleCreateConfig: {
    title: getTranslation('users'),
    actions: [
      ['ActionCreate', {
        title: getTranslation('create'),
        messageWorking: `${getTranslation('creating')}...`,
        messageSuccess: `${getTranslation('created')}!`,
      }],
    ],
  },
  hidden: [
    ['condition.checkViewer', 'role', '!==', 'ADMIN'],
  ],
  redirect: [['redirect.unauthorized']],
  filters: [
    cloneItem(filterSearch, null, null, { searchPaths: ['name', 'email'] }),
    filterSortByName,
    filterPagination,
    ['FilterHidden', {
      id: 'nonSignupUsers',
      build: builder => builder.add('where', { signup: { NE: true } }),
    }],
  ],
  columns: [
    {
      path: 'name',
      title: getTranslation('name'),
      link: '/users/:_id',
    },
    {
      path: 'email',
      title: getTranslation('email'),
    },
    {
      path: 'role',
      title: getTranslation('role'),
      render: record => getTranslation(record.role),
    },
    {
      path: 'active',
      title: getTranslation('status'),
      render: record => (
        record.active
        ? getTranslation('ACTIVE')
        : getTranslation('INACTIVE')
      ),
    },
  ],
  singleEditConfig: {
    title: getTranslation('users'),
    actions: [
      ACTIONS.SAVE,
      ACTIONS.DELETE_ADMIN,
      ['ActionWithRequest', {
        name: 'activate',
        title: getTranslation('activate'),
        skip: [
          ['condition.check', { path1: 'data.active', value2: true }],
        ],
        getRequestConfig: props => ({
          url: `users/${props.data._id}/action/activate`,
          method: 'POST',
        }),
        handleSuccess: (data, props) => props.reload(),
      }],
      ['ActionWithRequest', {
        name: 'deactivate',
        title: getTranslation('deactivate'),
        skip: [
          ['condition.check', { path1: 'viewer._id', path2: 'data._id' }],
          ['condition.checkViewer', 'role', '!==', 'ADMIN'],
          ['condition.check', { path1: 'data.active', value2: false }],
        ],
        getRequestConfig: props => ({
          url: `users/${props.data._id}/action/deactivate`,
          method: 'POST',
        }),
        handleSuccess: (data, props) => props.reload(),
      }],
      ['ActionWithRequest', {
        name: 'send-activation-email',
        title: getTranslation('send_activation_email'),
        skip: [
          ['condition.check', { path1: 'data.active', value2: true }],
        ],
        getRequestConfig: props => ({
          url: `users/${props.data._id}/action/send-activation-email`,
          method: 'POST',
        }),
      }],
    ],
  },
  fields: [
    ['FieldText', {
      name: 'name',
      label: null,
      prefix: `${getTranslation('name')}:`,
      rules: [['validation.isRequired'], ['validation.isName']],
    }],
    ['FieldText', {
      name: 'email',
      label: null,
      prefix: `${getTranslation('email')}:`,
      autoComplete: 'new-email',
      rules: [['validation.isRequired'], ['validation.isEmail']],
    }],
    ['FieldSelect', {
      name: 'role',
      label: null,
      prefix: `${getTranslation('role')}:`,
      rules: [['validation.isRequired']],
      choices: Types.ROLE.map(role => ({
        value: role,
        label: getTranslation(role),
      })),
      initialValue: 'ADMIN',
      disabled: [isNotCreateActivity],
      virtual: [isNotCreateActivity],
      skip: [
        ['condition.checkViewer', 'role', '!==', 'ADMIN'],
      ],
    }],
    ['Divider', {
      name: 'passwordDivider',
      skip: [skipPassword],
    }],
    ['FieldText', {
      name: 'password',
      label: null,
      prefix: `${getTranslation('password')}:`,
      type: 'password',
      autoComplete: 'new-password',
      rules: [
        ['validation.isPassword'],
      ],
      skip: [skipPassword],
    }],
    ['FieldText', {
      name: 'confirmPassword',
      label: null,
      prefix: `${getTranslation('confirm_password')}:`,
      type: 'password',
      rules: [
        ['validation.isPasswordSame'],
      ],
      skip: [skipPassword],
      virtual: true,
    }],
    // Country (non admin)
  ],
}]).pages;
