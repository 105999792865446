import React, { Component } from 'react';

import AntdDivider from 'antd/lib/divider';

import Admin from 'hive-admin/src/components/Admin';
import mix from 'hive-admin/src/modules/mix';
import Configurable from 'hive-admin/src/components/Mixins/Configurable';
import SuperProps from 'hive-admin/src/components/Mixins/SuperProps';

export default class DividerText extends mix(
  Component,
  Configurable,
  SuperProps
) {
  static config = {
    isDivider: true,
    type: 'horizontal',
    orientation: 'center',
    skip: [],
    ghost: false,
    getSkip: props => Admin.test(
      props.skip || [],
      props,
      true,
      true,
    ),
  }

  static propsMap = {
    type: true,
    text: true,
    dashed: true,
    orientation: true,
  }

  static create(config = {}) {
    config = this.getConfig(config);
    if (!config.name) {
      config.name = `${Math.random()}`;
    }
    config.skip = Admin.compileFromLibrary(config.skip, true);
    const CurrentComponent = this;
    return {
      ...config,
      render: props => (
        <CurrentComponent
          key={config.name}
          {...config}
          {...props}
        />
      ),
    };
  }

  render() {
    return this.props.ghost
      ? <div style={{ width: '100%' }} />
      : this.props.text
      ? <AntdDivider {...this.getMappedProps()}>{this.props.text}</AntdDivider>
      : <AntdDivider {...this.getMappedProps()} />;
  }
}

Admin.addToLibrary(
  'DividerText',
  config => DividerText.create(config),
);
