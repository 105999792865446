module.exports.decimalize = (number, decimalSeparator = '.') => {
  let result = number;
  if (number < 0) {
    result *= -1;
  }
  const thousandSeparator = decimalSeparator === '.' ? ',' : '.';
  const decimalizedNumber = result.toFixed(2)
  .split('')
  .reverse()
  .join('')
  .split('.')
  .map((value, i) => (
    i === 1
    ? value
      .replace(/(.{3})/g, `$1${thousandSeparator}`)
      .replace(new RegExp(`\\${thousandSeparator}$`, 'g'), '')
    : value
  ))
  .join(decimalSeparator)
  .split('')
  .reverse()
  .join('');
  return number < 0 ? `-${decimalizedNumber}` : decimalizedNumber;
};

module.exports.capitalize = (word) => (
  `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`
);
