import COUNTRIES from '../assets/countries.json';
import ANIMAL_TYPES from '../assets/animals.rs.json';
import ANIMAL_RACES from '../assets/races.json';
import DIAGNOSES from '../assets/diagnoses.json';
import helpers from './helpers';
import dictionary from '../assets/dictionary.json';

const Types = {};

Types.COUNTRIES = COUNTRIES;

Types.ANIMAL_TYPES_MAP = ANIMAL_TYPES.reduce((agr, animal) => {
  const key = animal
  .split(' ')
  .map(word => (word.toUpperCase()))
  .join('_');

  agr[key] = animal.split(' ').map(word => helpers.capitalize(word)).join(' ');
  return agr;
}, {});

Types.ANIMAL_RACES = ANIMAL_RACES;

Types.DIAGNOSES = DIAGNOSES.sort((a, b) => a.localeCompare(b));

Types.ROLE = [
  'ADMIN',
  'VETERINARIAN',
];

Types.SEX = ['MALE', 'FEMALE'];

Types.CURRENCY = 'RSD';

Types.LANG_KEY = 'rs';

Types.helpers = {};

Types.helpers.getTranslation = (key, langKey = Types.LANG_KEY) => (
  dictionary[key][langKey]
  ? dictionary[key][langKey]
  : dictionary[key].en
);

Types.helpers.capitalize = word => (
  `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`
);

Types.helpers.calculate = number => Math.round(number * 100) / 100;

Types.helpers.decimalize = (number, decimalSeparator = '.') => {
  let result = number;
  if (number < 0) {
    result *= -1;
  }
  const thousandSeparator = decimalSeparator === '.' ? ',' : '.';
  const decimalizedNumber = result.toFixed(2)
  .split('')
  .reverse()
  .join('')
  .split('.')
  .map((value, i) => (
    i === 1
    ? value
      .replace(/(.{3})/g, `$1${thousandSeparator}`)
      .replace(new RegExp(`\\${thousandSeparator}$`, 'g'), '')
    : value
  ))
  .join(decimalSeparator)
  .split('')
  .reverse()
  .join('');
  return number < 0 ? `-${decimalizedNumber}` : decimalizedNumber;
};

export default Types;
