import React from 'react';

import Admin from 'hive-admin';
// eslint-disable-next-line max-len
import ActionSaveAndReloadOriginal from 'hive-admin/src/components/ActionSaveAndReload';

import { useHotkeys } from 'react-hotkeys-hook';

function ActionWrapper({ children, callback }) {
  useHotkeys(
    'ctrl+s, meta+s',
    (event) => {
      event.preventDefault();
      callback();
    },
    {
      enableOnContentEditable: true,
      enableOnFormTags: ['INPUT', 'TEXTAREA', 'SELECT'],
    },
    [callback],
  );
  return children;
}

export default class ActionSaveAndReload extends ActionSaveAndReloadOriginal {
  static config = {
    ...ActionSaveAndReloadOriginal.config,
    ghost: true,
    icon: 'save',
    renderAction: (props, instance) => (
      <ActionWrapper callback={instance.handleClick}>
        {instance.renderAction(props)}
      </ActionWrapper>
    ),
  }
}

Admin.addToLibrary(
  'ActionSaveAndReload',
  config => ActionSaveAndReload.create(config),
);
