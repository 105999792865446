import * as React from 'react';

function SvgPill(props) {
  return (
    <svg viewBox="0 0 59.996 59.996" width="1em" height="1em" {...props}>
      <path d="M55.036 4.96C51.838 1.761 47.569 0 43.015 0c-4.553 0-8.822 1.761-12.021 4.96L4.96 30.995C1.761 34.193 0 38.462 0 43.015c0 4.554 1.761 8.823 4.96 12.021 3.198 3.199 7.467 4.96 12.021 4.96s8.822-1.761 12.021-4.96l26.035-26.035c3.199-3.198 4.96-7.467 4.96-12.021s-1.762-8.822-4.961-12.02zm-1.414 22.627L41.601 39.608 20.388 18.395 32.409 6.374C35.229 3.553 38.996 2 43.015 2c4.02 0 7.786 1.553 10.607 4.374 2.821 2.82 4.374 6.587 4.374 10.606s-1.553 7.787-4.374 10.607z" />
    </svg>
  );
}

export default SvgPill;
