import React from 'react';
import isArray from 'lodash/isArray';
import moment from 'moment';
import { Link } from 'hive-admin/node_modules/react-router-dom';
import {
  parse as parseQuery,
  stringify as stringifyQuery,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from 'query-string';
import Button from 'antd/lib/button';

import {
  getLinkWithSearchFields,
} from '../../components/Links';

import { cloneItem, filterPagination } from '../common';
import clickAnchor from '../../helpers/clickAnchor';

import Types from '../../modules/types';

const { helpers: { getTranslation } } = Types;

const getValueForFieldDefault = value => (
  value && value.length
  ? value
  : undefined
);

export const filterPopulate = ['FilterHidden', {
  id: 'populate',
  build: builder => builder.add(
    'populate',
    { owner: true, animal: true, createdBy: true },
  ),
}];

export const filterSort = ['FilterHidden', {
  id: 'sort',
  build: builder => builder.add(
    'sort',
    { createdAt: -1 },
  ),
}];

export const report = [() => ({
  id: 'reportToday',
  IS_ACTION: true,
  section: 'bottom',
  render: props => (
    <Button
      type="primary"
      key={`${Math.random()}`.slice(2)}
      onClick={() => {
        const query = {
          from: moment().startOf('day').toJSON(),
          to: moment().endOf('day').toJSON(),
        };
        props.history.push(`?${stringifyQuery(query)}`);
      }}
    >
      {getTranslation('today')}
    </Button>
  ),
}), {}];

export const filterName = ['FilterField', {
  id: 'name',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => value && builder.add(
    'where',
    { OR: [
        { name: { REGEX: value, OPTIONS: 'ig' } },
        { diagnosis: { REGEX: value, OPTIONS: 'ig' } },
      ],
    }
  ),
  getValueForField: value => value || '',
  getValueForQuery: (value) => {
    value = !value
    ? undefined
    : value.target
    ? value.target.value
    : value;
    return !value || !value.length
    ? undefined
    : value;
  },
  field: ['FieldText', {
    name: 'name',
    placeholder: getTranslation('find_by_name'),
  }],
}];

export const filterOwner = ['FilterField', {
  id: 'owner',
  label: null,
  section: 'top',
  propsFromPage: ({ client, location, history }) => ({
    client,
    location,
    history,
    onChange: (id, value) => {
      const params = parseQuery(location.search);
      if (value && `${value}`.length) {
        params[id] = value;
      } else {
        delete params[id];
      }
      delete params.animal;
      const browserUrl = `${
        location.pathname
      }?${
        stringifyQuery(params)
      }`;
      history.replace(browserUrl);
    },
  }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { owner: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'owner',
    url: '/owners',
    placeholder: getTranslation('find_by_owner'),
    // mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
  }],
}];

export const filterAnimal = ['FilterField', {
  id: 'animal',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client, location: props.location }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { animal: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'animal',
    url: '/animals',
    placeholder: getTranslation('find_by_pet'),
    // mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
    getExtraQueryConditions: (props) => {
      const { owner } = parseQuery(props.location.search);
      return owner
      ? [{ owner }]
      : [];
    },
  }],
}];

export const [filterFrom, filterTo] = [
  ['from', getTranslation('after_this_date'), 'After', 'GTE'],
  ['to', getTranslation('before_this_date'), 'Before', 'LTE'],
// eslint-disable-next-line no-unused-vars
].map(([id, placeholder, label, operator]) => [
  'FilterField', {
    id,
    label: null,
    section: 'top',
    buildQuery: (value, builder) => value && builder.add(
      'where',
      { createdAt: { [operator]: value } },
    ),
    getValueForField: value => (value ? moment(value) : undefined),
    getValueForQuery: value => (
      value
      ? (
          id === 'from'
          ? moment(value).startOf('day')
          : moment(value).endOf('day')
        ).toJSON()
      : undefined
    ),
    field: ['FieldDatePicker', {
      name: id,
      placeholder,
      format: ['DD.MM.YYYY.'],
      showToday: false,
      renderExtraFooter: () => {
        const now = moment();
        return (
          <div>
            <span>
              {
                id === 'from'
                ? `${getTranslation('start_of_this')}: `
                : `${getTranslation('end_of_this')}: `
              }
            </span>
            {[
              [getTranslation('date_filter_footer_day'), 'day'],
              [getTranslation('date_filter_footer_week'), 'week'],
              [getTranslation('date_filter_footer_month'), 'month'],
              [getTranslation('date_filter_footer_year'), 'year'],
            ].reduce(
              (agr, [footerLabel, range], i, array) => {
                agr.push(
                  <span key={range}>
                    <Link
                      to={getLinkWithSearchFields(
                        // eslint-disable-next-line no-undef
                        window.location,
                        [
                          {
                            name: id,
                            value: (
                                id === 'from'
                              ? now.startOf(range)
                              : now.endOf(range)
                            ).toJSON(),
                            replace: true,
                          },
                        ],
                        { pathname: '/interventions' },
                      )}
                    >
                      {footerLabel}
                    </Link>
                  </span>
                );
                if (i < array.length - 1) {
                  // eslint-disable-next-line react/no-array-index-key
                  agr.push(<span key={`sep${i}`}> / </span>);
                }
                return agr;
              },
              [],
            )}
          </div>
        );
      },
    }],
  },
]);

export const filterExport = ['FilterField', {
  id: 'export',
  name: 'export',
  label: null,
  section: 'top',
  buildQuery: () => { },
  propsFromPage: ({ client, queryBuilder, restBaseServer }) => ({
    client,
    queryBuilder,
    restBaseServer,
  }),
  field: ['Action', {
    name: 'export',
    icon: 'download',
    title: 'Izveštaj', // FIXME: translate
    type: 'default',
    style: { width: '100%' },
    onClick: (props) => clickAnchor({
      url: `${props.restBaseServer}/interventions/export`,
      query: {
        access_token: props.client.getAccessToken(),
        query: props.queryBuilder.compile(),
      },
    }),
  }],
}];

export const filterVax = ['FilterField', {
  id: 'vacc',
  name: 'vacc',
  label: null,
  section: 'top',
  buildQuery: () => { },
  propsFromPage: ({ history, client, queryBuilder, restBaseServer }) => ({
    client,
    queryBuilder,
    restBaseServer,
    history,
  }),
  field: ['Action', {
    name: 'vacc',
    icon: '',
    title: 'Vakcine', // FIXME: translate
    type: 'default',
    style: { width: '100%' },
    onClick: (props) => {
      const query = {
        from: moment()
          .subtract(1, 'y')
          .subtract(5, 'd')
          .startOf('day')
          .toJSON(),
        to: moment()
          .subtract(1, 'y')
          .add(5, 'd')
          .startOf('day')
          .toJSON(),
        name: 'vakcinacija',
      };
      props.history.push(`?${stringifyQuery(query)}`);
    },
  }],
}];

export const filterExportDaily = ['FilterField', {
  id: 'exportDaily',
  name: 'exportDaily',
  label: null,
  section: 'top',
  buildQuery: () => { },
  propsFromPage: ({ client, queryBuilder, restBaseServer }) => ({
    client,
    queryBuilder,
    restBaseServer,
  }),
  field: ['Action', {
    name: 'exportDaily ',
    icon: 'download',
    title: 'Dnevni izveštaj', // FIXME: translate
    type: 'default',
    style: { width: '100%' },
    onClick: (props) => clickAnchor({
      url: `${props.restBaseServer}/interventions/export`,
      query: {
        access_token: props.client.getAccessToken(),
        query: JSON.stringify({
          ...props.queryBuilder.compile(),
          where: {
            AND: [
              { createdAt: { GTE: moment().startOf('day').toJSON() } },
              { createdAt: { LTE: moment().endOf('day').toJSON() } },
            ],
          },
        }),
      },
    }),
  }],
}];

const FILTERS = [
  ...[
    [filterOwner, 12],
    [filterAnimal, 12],
    [filterName, 8],
    [filterFrom, 8],
    [filterTo, 8],

    [filterExportDaily, 8],
    [filterExport, 8],
    [filterVax, 8],

  ].map(([filter, col]) => cloneItem(filter, col)),

  filterSort,
  filterPopulate,
  filterPagination,
  report,
];

export default FILTERS;
