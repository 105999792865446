import React from 'react';
import { FileAddOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'hive-admin/node_modules/react-router-dom';
import Popover from 'antd/lib/popover';

import {
  OwnerInterventionLink,
  DateLink,
  getLinkWithSearchFields,
} from '../../components/Links';

import Types from '../../modules/types';

const { LANG_KEY: langKey, helpers: { getTranslation } } = Types;

const columnCreateIntervention = {
  path: 'createIntervention',
  title: null,
  width: '5px',
  render: record => (
    record.alive
    ? (
      <Popover
        content={(
          <>
            <span>
              {`${
                getTranslation('create_intervention', langKey)
              } `}
            </span>
            {' '}
            <Link
              to={getLinkWithSearchFields(
                null,
                [{
                  name: 'animal',
                  value: record._id,
                  replace: true,
                }, {
                  name: 'owner',
                  value: record.owner._id,
                  replace: true,
                }],
                { pathname: '/interventions/create' },
              )}
            >
             {getTranslation('for_this_pet', langKey)}
            </Link>
          </>
        )}
      >
        <FileAddOutlined
          style={{ fontSize: '15px' }}
        />
      </Popover>
    )
    : null
  ),
};
const columnName = {
  path: 'name',
  title: getTranslation('name', langKey),
  render: record => (
    !record.alive
    ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
      <Popover
        title={null}
        content={(
          <div>
            <span>
              {`${
                getTranslation('show_all', langKey)
              } ${
                getTranslation('interventions', langKey).toLowerCase()
              } `}
            </span>
            <Link
              to={getLinkWithSearchFields(
                null,
                [{
                  name: 'animal',
                  value: record._id,
                  replace: true,
                }],
                { pathname: '/interventions' },
              )}
            >
              {getTranslation('for_this_pet', langKey)}
            </Link>
          </div>
        )}
      >
        <Link
          to={`/animals/${record._id}`}
          style={{ textDecoration: 'line-through' }}
        >
          {record.name}
        </Link>
      </Popover>
      <Popover content={getTranslation('deceased', langKey)}>
        <span
          style={{ fontFamily: 'Arial, sans, serif', fontSize: '15px' }}
        >
          &#10013;
        </span>
      </Popover>
      </div>
    )
    : (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
      <Popover
        title={null}
        content={(
          <div>
            <span>
              {`${
                getTranslation('show_all', langKey)
              } ${
                getTranslation('interventions', langKey).toLowerCase()
              } `}
            </span>
            <Link
              to={getLinkWithSearchFields(
                null,
                [{
                  name: 'animal',
                  value: record._id,
                  replace: true,
                }],
                { pathname: '/interventions' },
              )}
            >
              {getTranslation('for_this_pet', langKey)}
            </Link>
          </div>
        )}
      >
        <Link
          to={`/animals/${record._id}`}
        >
          {record.name}
        </Link>
      </Popover>
      {record.allergies.length > 0 && (
        <Popover
          content={`${getTranslation('allergies', langKey)}: ${
            record.allergies.reduce((agr, { allergy }, i) => {
              if (i === (record.allergies.length - 1)) {
                agr += `${allergy || ''}`;
              } else {
                agr += `${allergy || ''}, `;
              }
              return agr;
            },
            '')
          }`}
        >
          <span
            style={{ color: '#ff4d4f', fontWeight: 600 }}
          >
            A
          </span>
        </Popover>
        )}
      </div>
      )
  ),
};

const columnSex = {
  path: 'sex',
  title: getTranslation('sex', langKey),
  render: record => (
    record.sex === 'MALE'
    ? getTranslation('sex_m')
    : getTranslation('sex_f')
  ),
  width: '5px',
  align: 'center',
};

const columnType = {
  path: 'type',
  title: getTranslation('animal_type', langKey),
  render: record => (`${Types.ANIMAL_TYPES_MAP[record.type.toUpperCase()]}`),
};

const columnBreed = {
  path: 'breed',
  title: getTranslation('breed', langKey),
  render: record => (record.breed ? record.breed : 'N / A'),
};

const columnOwner = {
  path: 'owner',
  title: getTranslation('owner', langKey),
  render: (record, column, props) => (
    <OwnerInterventionLink
      location={props.location}
      subject={record.owner}
      pathname="/interventions"
    />
  ),
};
const columnIdNumber = {
  path: 'idNumber',
  title: getTranslation('id_number', langKey),
  render: record => (record.idNumber ? record.idNumber : 'N / A'),
};
const columnDateOfBirth = {
  path: 'dateOfBirth',
  title: getTranslation('date_of_birth', langKey),
  render: record => (
    `${moment(record.dateOfBirth).format('DD.MM.YYYY.')}`
  ),
};

const columnCreatedAt = {
  path: 'createdAt',
  title: getTranslation('created_at'),
  render: (record, column, props) => (
    <DateLink
      location={props.location}
      date={record.createdAt}
    >
      {moment(record.createdAt).format('DD.MM.YYYY.')}
    </DateLink>
  ),
};

export default [
  columnCreateIntervention,
  columnName,
  columnSex,
  columnType,
  columnBreed,
  columnOwner,
  columnIdNumber,
  columnDateOfBirth,
  columnCreatedAt,
];
