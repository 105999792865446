import React from 'react';
import moment from 'moment';
import Admin from 'hive-admin';

import getKey from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import {
  parse as parseQuery,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from 'query-string';

import Types from '../modules/types';

import {
  OwnerTransactionLink,
} from '../components/Links';

import FILTERS from '../filters/transactions';

import { ACTIONS } from './common';

const { helpers: { getTranslation, decimalize } } = Types;

const isNotCreateActivity = ['condition.check', {
  path1: 'activity',
  op: '!==',
  value2: 'create',
}];

export default Admin.compileFromLibrary(['GroupResource', {
  title: getTranslation('transactions'),
  icon: 'dollar',
  path: '/transactions',
  redirect: [['redirect.unauthorized']],
  archiveConfig: {
    createButtonLabel: getTranslation('create_new_item'),
    icon: 'dollar',
    title: getTranslation('transactions'),
    label: getTranslation('transactions'),
    filters: FILTERS,
    columns: [
      // { path: 'count', title: '#' },
      {
        path: 'name',
        title: getTranslation('name'),
        link: '/transactions/:_id',
      },
      {
        path: 'owner',
        title: getTranslation('owner'),
        render: (record, column, props) => (
          <OwnerTransactionLink
            location={props.location}
            subject={record.owner}
            pathname="/transactions"
          />
        ),
      },
      {
        path: 'amount',
        title: getTranslation('amount'),
        render: record => `${decimalize(record.amount)} ${Types.CURRENCY}`,
      },
      {
        path: 'createdAt',
        title: getTranslation('created_at'),
        label: 'Created At',
        render: record => (
          `${moment(record.createdAt).format('DD.MM.YYYY.')}` // TODO add hours if neccessary
        ),
      },
    ],
  },
  singleConfig: {
    hidden: true,
    alias: '/transactions',
    backButtonPaths: ['/transactions'],
    headerTitle: getTranslation('create'),
  },
  singleCreateConfig: {
    title: getTranslation('transactions'),
    actions: [ACTIONS.CREATE],
  },
  singleEditConfig: {
    title: getTranslation('transactions'),
    actions: [
      ACTIONS.SAVE,
      ACTIONS.DELETE_ADMIN,
    ],
  },
  fields: [
    ['FieldConnectionSelect', {
      name: 'owner',
      label: null,
      prefix: `${getTranslation('owner')}:`,
      url: '/owners',
      getChoiceLabel: choice => `${
        choice.data.name
      } (${
        choice.data.balance
      } ${
        Types.CURRENCY
      })`,
      searchPaths: ['name'],
      // getExtraQueryConditions: () => [{ role: 'ADMIN' }],
      rules: [['validation.isRequired']],
      disabled: [isNotCreateActivity],
      virtual: [isNotCreateActivity],
      getInitialValue: (fieldConfig, formProps) => {
        if (formProps.data) {
          const value = getKey(formProps.data, fieldConfig.name);
          if (!isUndefined(value)) {
            return value;
          }
        }
        const { owner } = parseQuery(formProps.location.search);
        if (owner) {
          return `${owner}`;
        }
        return fieldConfig.initialValue;
      },
    }],
    ['FieldText', {
      name: 'name',
      label: null,
      prefix: `${getTranslation('name')}:`,
      rules: [['validation.isRequired']],
      getInitialValue: (fieldConfig, formProps) => {
        if (formProps.data) {
          const value = getKey(formProps.data, fieldConfig.name);
          if (!isUndefined(value)) {
            return value;
          }
        }
        const { name } = parseQuery(formProps.location.search);
        if (name) {
          return `${JSON.parse(name)}`;
        }
        return fieldConfig.initialValue;
      },
    }],
    ['FieldText', {
      name: 'amount',
      label: null,
      prefix: `${getTranslation('amount')}:`,
      rules: [['validation.isRequired']],
      getInitialValue: (fieldConfig, formProps) => {
        if (formProps.data) {
          const value = getKey(formProps.data, fieldConfig.name);
          if (!isUndefined(value)) {
            return value;
          }
        }
        const { amount } = parseQuery(formProps.location.search);
        if (amount) {
          return amount;
        }
        return fieldConfig.initialValue;
      },
    }],
    ['Divider', { name: 'dividerSwitches' }],
    ['FieldSwitch', {
      name: 'minus',
      label: 'Minus',
      col: 8,
    }],
    ['FieldSwitch', {
      name: 'debtPayment',
      label: `${getTranslation('debt_payment')}`,
      col: 8,
      getInitialValue: (fieldConfig, formProps) => {
        if (formProps.data) {
          const value = getKey(formProps.data, fieldConfig.name);
          if (!isUndefined(value)) {
            return value;
          }
        }
        const { debtPayment } = parseQuery(formProps.location.search);
        if (debtPayment) {
          return !!debtPayment;
        }
        return fieldConfig.initialValue;
      },
    }],
    ['FieldSwitch', {
      name: 'sales',
      label: `${getTranslation('sales')}`,
      col: 8,
    }],
    // ['FieldConnectionSelect', {
    //   name: 'intervention',
    //   label: null,
    //   prefix: `${getTranslation('intervention')}:`,
    //   url: '/interventions',
    //   // getChoiceLabel: choice => choice.data.name,
    //   searchPaths: ['name'],
    //   // getExtraQueryConditions: () => [{ role: 'ADMIN' }],
    //   // rules: [['validation.isRequired']],
    //   disabled: [isNotCreateActivity],
    //   virtual: [isNotCreateActivity],
    // }],
  ],
}]).pages;
