import getKey from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import moment from 'moment';
import {
  parse as parseQuery,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from 'query-string';

import Types from '../../modules/types';

const { ANIMAL_TYPES_MAP, ANIMAL_RACES, helpers: { getTranslation } } = Types;

const ANIMAL_CHOICES_MAP = Object.keys(ANIMAL_TYPES_MAP).map(key => (
  { value: key, label: ANIMAL_TYPES_MAP[key] }
));

const isNotCreateActivity = ['condition.check', {
  path1: 'activity',
  op: '!==',
  value2: 'create',
}];

const fieldName = ['FieldText', {
  name: 'name',
  label: null,
  prefix: `${getTranslation('name')}:`,
  rules: [['validation.isRequired']],
}];

const fieldOwner = ['FieldConnectionSelect', {
  name: 'owner',
  label: null,
  prefix: `${getTranslation('owner')}:`,
  // placeholder: getTranslation('select_an_owner'),
  url: '/owners',
  getInitialValue: (fieldConfig, formProps) => {
    if (formProps.data) {
      const value = getKey(formProps.data, fieldConfig.name);
      if (!isUndefined(value)) {
        return value;
      }
    }
    const { owner } = parseQuery(formProps.location.search);
    if (owner) {
      return `${owner}`;
    }
    return fieldConfig.initialValue;
  },
  // getChoiceLabel: choice => choice.data.name,
  searchPaths: ['name'],
  // getExtraQueryConditions: () => [{ role: 'ADMIN' }],
  rules: [['validation.isRequired']],
  disabled: [isNotCreateActivity],
  virtual: [isNotCreateActivity],
}];

const fieldType = ['FieldSelect', {
  name: 'type',
  label: null,
  prefix: `${getTranslation('animal_type')}:`,
  rules: [],
  showSearch: true,
  initialValue: ANIMAL_CHOICES_MAP[0].value,
  choices: ANIMAL_CHOICES_MAP,
  filterOption: (input, option) => (option.props.children.toLowerCase()
    .replace(/š/ig, 's')
    .replace(/[čć]/ig, 'c')
    .replace(/ž/ig, 'z')
    .indexOf(input.toLowerCase()) >= 0
  ),
}];

const fieldBreed = ['FieldSelect', {
  name: 'breed',
  label: null,
  showSearch: true,
  prefix: `${getTranslation('breed')}:`,
  rules: [],
  optionFilterProp: 'children',
  initialValue: ANIMAL_RACES[ANIMAL_RACES.length - 1],
  choices: ANIMAL_RACES,
  filterOption: (input, option) => (option.props.children.toLowerCase()
    .replace(/š/ig, 's')
    .replace(/[čć]/ig, 'c')
    .replace(/ž/ig, 'z')
    .indexOf(input.toLowerCase()) >= 0
  ),
}];

const fieldColor = ['FieldText', {
  name: 'color',
  label: null,
  prefix: `${getTranslation('color')}:`,
  // rules: [['validation.isRequired']],
}];
const fieldIdNumber = ['FieldText', {
  name: 'idNumber',
  label: null,
  prefix: `${getTranslation('id_number')}:`,
  rules: [],
}];
const fieldAlive = ['FieldRadio', {
  name: 'alive',
  label: `${getTranslation('alive')}:`,
  rules: [['validation.isRequired']],
  initialValue: true,
  choices: [{
    value: true,
    label: getTranslation('alive1'),
  }, {
    value: false,
    label: getTranslation('dead'),
  }],
}];
const fieldSex = ['FieldRadio', {
  name: 'sex',
  label: `${getTranslation('sex')}:`,
  initialValue: 'MALE',
  choices: Types.SEX.map(sex => ({
    value: sex,
    label: getTranslation(sex),
  })),
  rules: [['validation.isRequired']],
}];

const fieldDateOfBirth = ['FieldDatePicker', {
  name: 'dateOfBirth',
  label: `${getTranslation('date_of_birth')}:`,
  placeholder: null,
  prepareValueForInput: value => (value ? moment(value) : null),
  format: ['DD.MM.YYYY.'],
}];

const fieldPassportNumber = ['FieldText', {
  name: 'passportNumber',
  label: null,
  prefix: `${getTranslation('passport_number')}:`,
}];

const fieldPassportIssueAuthority = ['FieldText', {
  name: 'passportIssueAuthority',
  label: null,
  initialValue: 'Gaj-Vet DOO',
  prefix: `${getTranslation('passport_issue_authority')}:`,
}];

const fieldPassportIssueDate = ['FieldDatePicker', {
  name: 'passportIssueDate',
  label: `${getTranslation('passport_issue_date')}:`,
  placeholder: null,
  prepareValueForInput: value => (value ? moment(value) : null),
  format: ['DD.MM.YYYY.'],
}];

const fieldAllergies = ['FieldSortableList', {
  name: 'allergies',
  addButtonLabel: `${getTranslation('add')}`,
  label: `${getTranslation('allergies')}:`,
  fields: [
    ['FieldText', {
      name: 'allergy',
      label: null,
      prefix: `${getTranslation('allergy')}:`,
    }],
  ],
}];
const fieldAdditionalInfo = ['FieldTextArea', {
  name: 'additionalInfo',
  label: `${getTranslation('additional_info')}:`,
  autosize: {
    minRows: 3,
  },
}];

export default [
  fieldName,
  fieldOwner,
  fieldType,
  fieldBreed,
  fieldColor,
  ['Divider', { name: 'dividerInfo' }],
  fieldIdNumber,
  fieldPassportNumber,
  fieldPassportIssueAuthority,
  fieldPassportIssueDate,
  fieldAlive,
  fieldSex,
  fieldDateOfBirth,
  ['Divider', { name: 'dividerAdditionalInfo' }],
  fieldAllergies,
  fieldAdditionalInfo,
];
