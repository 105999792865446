import React from 'react';

import Admin from 'hive-admin';
import moment from 'moment';

import Types from '../../modules/types';

import FILTERS from './filters';
import COLUMNS from './columns';
import {
  CREATE,
  EDIT,
  fieldServices,
  fieldDrugs,
  fieldCharges,
  fieldsPaid,
} from './fields';

import { ACTIONS, cloneItem } from '../common';

import DownloadInvoice from '../../components/Profaktura';
import actionPotvrda from './actionPotvrda';

const { helpers: { getTranslation } } = Types;

export default Admin.compileFromLibrary(['GroupResource', {
  title: getTranslation('interventions'),
  icon: 'file',
  path: '/interventions',
  redirect: [['redirect.unauthorized']],
  archiveConfig: {
    createButtonLabel: getTranslation('create_new_item'),
    icon: 'file',
    title: getTranslation('interventions'),
    label: getTranslation('interventions'),
    filters: FILTERS,
    columns: COLUMNS,
  },
  singleConfig: {
    hidden: true,
    alias: '/interventions',
    backButtonPaths: ['/interventions'],
    backButtonLabel: getTranslation('close'),
    headerTitle: getTranslation('create'),
  },
  singleCreateConfig: {
    title: getTranslation('interventions'),
    fields: CREATE,
    actions: [ACTIONS.CREATE],
  },
  singleEditConfig: {
    title: getTranslation('interventions'),
    loadUrl: 'interventions/:id/?populateAll=true', // TODO maybe actually save data about owner / animal in intervention
    actions: [
      ACTIONS.SAVE,
      ['Action', {
        name: 'download-sticker',
        title: 'Profaktura',
        renderAction: (props, action) => (
          <DownloadInvoice
            intervention={props.data}
            fileName={`${
              moment(props.data.createdAt).format('DD.MM.YYYY')
            }_${
              props.data.animal.name
            }_${
              props.data.name.split(',').join('').split(' ').join('_')
            }.pdf`}
          >
            {
              ({ loading }) => (
                loading
                ? action.renderAction({
                  ...props,
                  title: 'Loading...',
                  disabled: true,
                })
                : action.renderAction(props)
              )
            }
          </DownloadInvoice>
        ),
      }],
      actionPotvrda,
      ['ActionWithFormModal', {
        label: `${getTranslation('update')}`,
        title: `${getTranslation('update')}`,
        modalProps: {
          centered: true,
          closable: true,
          wrapClassName: 'edit-modal',
        },
        fields: [
          cloneItem(fieldServices, null, null, { name: 'servicesEdit' }),
          cloneItem(fieldDrugs, null, null, { name: 'drugsEdit' }),
          cloneItem(fieldCharges, null, null, { name: 'chargesEdit' }),
          ...fieldsPaid,
          ['FieldText', {
            name: 'paid',
            label: null,
            getInitialValue: () => 0,
            prefix: `${getTranslation('paid')}:`,
            addonAfter: Types.CURRENCY,
            type: 'number',
            skip: [
              [
                'condition.testFieldValue',
                'paidFull',
                '===',
                true,
              ],
            ],
            rules: [
              // ['validation.isRequired'],
              ['validation.isNumber'],
            ],
          }],
        ],
        actions: [
          ['ActionWithFormBasedRequest', {
            title: getTranslation('add'),
            skip: [],
            handleSuccess: (_data, props) => props.reload(),
            getRequestConfig: (props, data) => ({
              url: `interventions/${props.data._id}/edit-charges`,
              method: 'post',
              data,
            }),
          }],
        ],
      }],
      ACTIONS.DELETE,
    ],
    fields: EDIT,
  },
}]).pages;
