/* eslint-disable max-len */
import React from 'react';
import {
  Page,
  Document,
  Image,
  StyleSheet,
  View,
  Text,
  PDFDownloadLink,
  Font,
// eslint-disable-next-line import/no-extraneous-dependencies
} from '@react-pdf/renderer';

import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import moment from 'moment';

import Types from '../modules/types';

import logo from '../assets/logo-bw.png';

import Poppins from '../assets/pdf-assets/poppins-regular.ttf';
import PoppinsMedium from '../assets/pdf-assets/poppins-medium.ttf';
import PoppinsBold from '../assets/pdf-assets/poppins-bold.ttf';

import Montserrat from '../assets/pdf-assets/montserrat-regular.ttf';
import MontserratMedium from '../assets/pdf-assets/montserrat-medium.ttf';
import MontserratBold from '../assets/pdf-assets/montserrat-bold.ttf';

const { helpers: { decimalize } } = Types;

Font.register({
  family: 'Poppins',
  fonts: [
    { src: Poppins },
    { src: PoppinsMedium, fontWeight: 'medium' },
    { src: PoppinsBold, fontWeight: 'bold' },
  ],
});

Font.register({
  family: 'Montserrat',
  fonts: [
    { src: Montserrat },
    { src: MontserratMedium, fontWeight: 'medium' },
    { src: MontserratBold, fontWeight: 'bold' },
  ],
});

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    fontFamily: 'Montserrat',
    fontSize: 10,
    padding: 20,
    lineHeight: 1.2,
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100vh',
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textRow: {
    borderBottom: '1px solid #bfbbbb',
    marginTop: 3,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#bfbbbb',
  },
  rowTable: {
    flexDirection: 'row',
    borderBottomColor: '#bfbbbb',
    borderBottomWidth: 1,
    alignItems: 'center',
    textAlign: 'center',
    fontWeight: 400,
    flexGrow: 1,
  },
});

const info = {
  name: 'Veterinarska Ambulanta Viva Vet',
  address: 'Miloša Trebinjca 44, 26000 Pančevo',
  phone: '013 333 513',
  email: 'vivavet@gmail.com',
  website: 'vivavet.rs',
};

const columnsAnimal = [
  { title: 'Ime', field: 'name' },
  { title: 'Vrsta', field: 'type', cb: (value) => Types.ANIMAL_TYPES_MAP[value] },
  { title: 'Rasa', field: 'breed' },
  { title: 'Datum rodjenja', field: 'dateOfBirth', cb: (value) => moment(value).format('DD.MM.YYYY.') },
  { title: 'Broj cipa', field: 'idNumber' },
  { title: 'Pol', field: 'sex', cb: (value) => (value === 'MALE' ? 'Muški' : 'Ženski') },
];

const columnsOwner = [
  { title: 'Ime', field: 'name' },
  { title: 'Adresa', field: 'address' },
  { title: 'Mesto', field: 'city' },
  { title: 'JMBG', field: 'uniqueIdentificationNumber' },
  { title: 'Telefon', field: 'phoneNumber' },
  { title: 'Email', field: 'email' },
];

const columns = [
  { name: 'Dijagnoza', field: 'diagnosis' },
  { name: 'Anamneza', field: 'anamnesis' },
  { name: 'Nalaz', field: 'finding' },
  { name: 'Preporuka', field: 'recommendation' },
  { name: 'Terapija', field: 'therapy' },
];

const tableColumnsWidth = [60, 15, 10, 15];

const InvoiceItemsTable = ({ intervention }) => {
  const chargeRows = [
    ...intervention.services,
    ...intervention.drugs,
    ...intervention.charges,
  ].reduce(
    (agr, item) => {
      const name = item.name || 'Naknada';
      const { price, quantity = 1 } = item;
      const total = price * quantity;
      agr.push([
        name,
        `${decimalize(price)} ${Types.CURRENCY}`,
        quantity,
        `${decimalize(total)} ${Types.CURRENCY}`,
      ]);
      return agr;
    },
    [],
  );

  return (
    <View style={styles.tableContainer}>
      {columns.map((column, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <View style={[styles.rowTable]} key={index}>
          <Text
            style={{
              width: '15%',
              height: '100%',
              padding: 5,
              textAlign: 'left',
              alignSelf: 'flex-start',
            }}
          >
            {column.name}
          </Text>
          <Text
            style={{
              width: '85%',
              padding: 5,
              height: '100%',
              borderLeft: '1px solid #bfbbbb',
              wordWrap: 'break-word',
              minHeight: '40px',
              textAlign: 'left',
              alignItems: 'center',
            }}
          >
            {
              intervention[column.field]
              ? (
                isArray(intervention[column.field])
                ? intervention[column.field].join(', ')
                : intervention[column.field]
              )
              : ' - '
            }
          </Text>
        </View>
      ))}

      <View style={[styles.rowTable, { width: '100%' }]}>
        {[
          ['Predmet'],
          ['Cena'],
          ['Količina'],
          ['Ukupno'],
        ].map(([name], index) => (
          <Text
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{
              width: `${tableColumnsWidth[index]}%`,
              padding: 5,
              textAlign: 'left',
              borderRight: index !== 3 ? '1px solid #bfbbbb' : 'none',
            }}
          >
            {name}
          </Text>
        ))}
      </View>
      {chargeRows.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <View style={[styles.rowTable, { width: '100%' }]} key={index}>
          {item.map((name, i) => (
            <Text
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              style={{
                width: `${tableColumnsWidth[i]}%`,
                padding: 5,
                textAlign: i === 0 ? 'left' : 'right',
                borderRight: i !== 3 ? '1px solid #bfbbbb' : 'none',
              }}
            >
              {name}
            </Text>
          ))}
        </View>
      ))}

      <View>
        <Text
          style={{
            width: '100%',
            padding: 5,
            textAlign: 'right',
          }}
        >
        {`${decimalize(intervention.amount)} ${Types.CURRENCY}`}
        </Text>
      </View>
    </View>
  );
};

const Invoice = ({ intervention }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* <Image style={styles.logo} src={logo} /> */}
      <View style={[styles.row]}>
        <View style={{ width: '33%' }}>
          <Image
            src={logo}
            style={{ width: 111, height: 90 }}
          />
        </View>
        <View
          style={{
            width: '33%',
            textAlign: 'center',
          }}
        >
          <Text>{moment(intervention.createdAt).format('DD.MM.YYYY. HH:mm')}</Text>
        </View>
        <View
          style={{
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              alignItems: 'flex-end',
            }}
          >
            {Object.values(info).map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Text key={index}>{item}</Text>
            ))}
          </View>
        </View>
      </View>

      <View style={{ width: '100%' }}>
        <View style={styles.row}>
          <View
            style={[styles.row, {
              width: '100%',
              padding: 5,
            }]}
          >
            <View
              style={{}}
            >
              {columnsAnimal.map((item, index) => (
                <Text
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  style={{
                    marginRight: 10,
                    borderBottom: '1px solid white',
                    marginTop: 3,
                  }}
                >
                  {item.title}
                </Text>
              ))}
            </View>
            <View style={{ textAlign: 'left', flex: 1 }}>
              {columnsAnimal.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Text style={styles.textRow} key={index}>
                  {
                    isFunction(item.cb)
                    ? item.cb(intervention.animal[item.field])
                    : (intervention.animal[item.field] || ' - ')
                  }
                </Text>
              ))}
            </View>
          </View>
          <View style={[styles.row, {
            borderLeft: '1px solid #bfbbbb',
          }]}
          >
            <View
              style={[styles.row, {
                width: '100%',
                padding: 5,
              }]}
            >
              <View
                style={{}}
              >
                {columnsOwner.map((item, index) => (
                  <Text
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    style={{
                      marginRight: 10,
                      borderBottom: '1px solid white',
                      marginTop: 3,
                    }}
                  >
                    {item.title}
                  </Text>
                ))}
              </View>
              <View style={{ textAlign: 'left', flex: 1 }}>
                {columnsOwner.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Text style={styles.textRow} key={index}>
                    {
                      isFunction(item.cb)
                      ? item.cb(intervention.owner[item.field])
                      : (intervention.owner[item.field] || ' - ')
                    }
                  </Text>
                ))}
              </View>
            </View>
          </View>
        </View>

        <InvoiceItemsTable intervention={intervention} />

        <Text
          style={{
            textAlign: 'center',
            marginTop: 1,
          }}
        >
          Preuzimanjem izvestaja, vlasnik životinje potvrdjuje da su ovako iskazani anamnestički podaci tačni, da su mu predočeni svi benefiti i potencijalni rizici izvršene dijagnostičke i terapijske procedure, kao i svi rizici od njenog nepreduzimanja. Vlasnik takodje potvrdjuje da su mu uputstva u potpunosti jasna i da će ih se pridržavati, kao i režima neophodnih kontrola.
        </Text>
      </View>
      <View>
        <Text style={{ textAlign: 'center', borderTop: '1px solid #bfbbbb' }}>
          Ponedeljak-Petak 09-20h | Subota 09-14h | Nedelja 09-14h
        </Text>
      </View>
    </Page>
  </Document>
);

const DownloadInvoice = ({
  children,
  fileName,
  ...props
}) => (
  <>
    <PDFDownloadLink
      document={<Invoice {...props} />}
      fileName={fileName || 'profaktura.pdf'}
    >
      {children}
    </PDFDownloadLink>
  </>
);

export default DownloadInvoice;
