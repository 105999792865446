import React from 'react';
import { Icon } from 'antd';

import Admin from 'hive-admin';

import Types from '../modules/types';
import SvgPill from '../components/SvgPill';

import {
  ACTIONS,
  filterPagination,
  filterSortByName,
  filterSearch,
} from './common';

const { helpers: { getTranslation, decimalize } } = Types;

export default Admin.compileFromLibrary(['GroupResource', {
  title: getTranslation('drugs'),
  icon: () => <Icon component={SvgPill} />,
  path: '/drugs',
  redirect: [['redirect.unauthorized']],
  archiveConfig: {
    createButtonLabel: getTranslation('create_new_item'),
    icon: () => <Icon component={SvgPill} />,
    title: getTranslation('drugs'),
    label: getTranslation('drugs'),
    filters: [
      filterSearch,
      filterSortByName,
      filterPagination,
    ],
    columns: [
      {
        path: 'name',
        title: getTranslation('name'),
        link: '/drugs/:_id',
      },
      {
        path: 'stock',
        title: getTranslation('stock'),
        render: record => `${(
          record.unit === 'PC'
          ? `${record.stock} ${getTranslation('unit_pc')}`
          : `${record.stock} ${getTranslation('unit_ml')}`
        )}`,
      },
      {
        path: 'price',
        title: getTranslation('price'),
        render: record => `${decimalize(record.price)} ${Types.CURRENCY} / ${
          record.unit === 'PC'
          ? `${getTranslation('unit_pc')}`
          : `${getTranslation('unit_ml')}`
        } `,
      },
    ],
    getArchiveFilters: (props, filtersOriginal) => (
      filtersOriginal.filter(filter => (
          !filter.isAvailable
        ? true
        : filter.isAvailable(props, filter)
      ))
    ),
  },
  singleConfig: {
    hidden: true,
    alias: '/drugs',
    backButtonPaths: ['/drugs'],
    headerTitle: getTranslation('create'),
  },
  singleCreateConfig: {
    title: getTranslation('drugs'),
    actions: [ACTIONS.CREATE],
  },
  singleEditConfig: {
    title: getTranslation('drugs'),
    actions: [
      ACTIONS.SAVE,
      ACTIONS.DELETE_ADMIN,
    ],
  },
  fields: [
    ['FieldText', {
      name: 'name',
      label: null,
      prefix: `${getTranslation('name')}:`,
      rules: [['validation.isRequired']],
    }],
    ['FieldRadio', {
      name: 'unit',
      label: `${getTranslation('unit')}:`,
      rules: [['validation.isRequired']],
      initialValue: 'PC',
      choices: [{
        value: 'PC',
        label: getTranslation('piece'),
      }, {
        value: 'ML',
        label: getTranslation('mililiter'),
      }],
    }],
    ['FieldText', {
      name: 'stock',
      label: null,
      prefix: `${getTranslation('stock')}:`,
      // prefix: record => `${(
      //   record.form.getFieldValue('unit') === 'PC'
      //   ? getTranslation('stock_in_pieces')
      //   : getTranslation('stock_in_mililiters')
      // )}`,
      rules: [['validation.isRequired']],
    }],
    ['FieldText', {
      addonAfter: Types.CURRENCY,
      name: 'price',
      label: null,
      prefix: `${getTranslation('price')}:`,
      // label: record => `${(
      //   record.form.getFieldValue('unit') === 'PC'
      //   ? getTranslation('price_per_piece')
      //   : getTranslation('price_per_mililiter')
      // )}`,
      rules: [['validation.isRequired']],
    }],
  ],
}]).pages;
