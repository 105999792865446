// import isUndefined from 'lodash/isUndefined';

import Types from '../modules/types';

const { helpers: { getTranslation } } = Types;

export default [
  'PageSingle',
  {
    icon: 'tool',
    path: '/settings/general',
    title: getTranslation('settingsGeneral'),
    headerTitle: getTranslation('settingsGeneral'),
    renderHeaderTitle: () => getTranslation('settingsGeneral'),
    hidden: false,
    activity: 'edit',
    url: '/settings/general',
    redirect: [['redirect.unauthorized']],
    actions: [['ActionSaveAndReload']],
    // skip: [
    //   ['condition.checkViewer', 'role', '!==', 'ADMIN'],
    // ],
    fields: [
      ['FieldTitle', {
        title: getTranslation('confirmationValueTitle'),
      }],
      ['FieldText', {
        name: 'confirmationValueStart',
        label: getTranslation('confirmationValueStart'),
        placeholder: '0',
        col: { xs: 24, lg: 8 },
        type: 'number',
        rules: [['validation.isNumber']],
        addonBefore: 'P',
      }],
      ['FieldText', {
        name: 'confirmationValueEnd',
        label: getTranslation('confirmationValueEnd'),
        placeholder: '0',
        col: { xs: 24, lg: 8 },
        type: 'number',
        rules: [['validation.isNumber']],
        addonBefore: 'P',
      }],
      ['FieldText', {
        name: 'confirmationValue',
        label: getTranslation('confirmationValue'),
        placeholder: '0',
        addonBefore: 'P',
        type: 'number',
        rules: [['validation.isNumber']],
        col: { xs: 24, lg: 8 },
      }],
    ],
  },
];
