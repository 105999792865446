/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React from 'react';
import {
  Page,
  Document,
  Image,
  StyleSheet,
  View,
  Text,
  PDFDownloadLink,
  Font,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from '@react-pdf/renderer';

import moment from 'moment';

import Types from '../modules/types';

import logo from '../assets/grb-srbija.jpg';

import Poppins from '../assets/pdf-assets/poppins-regular.ttf';
import PoppinsMedium from '../assets/pdf-assets/poppins-medium.ttf';
import PoppinsBold from '../assets/pdf-assets/poppins-bold.ttf';

import Montserrat from '../assets/pdf-assets/montserrat-regular.ttf';
import MontserratMedium from '../assets/pdf-assets/montserrat-medium.ttf';
import MontserratBold from '../assets/pdf-assets/montserrat-bold.ttf';

const { helpers: { decimalize, getTranslation } } = Types;

Font.register({
  family: 'Montserrat',
  fonts: [
    { src: Montserrat },
    { src: MontserratMedium, fontWeight: 'medium' },
    { src: MontserratBold, fontWeight: 'bold' },
  ],
});

Font.register({
  family: 'Poppins',
  fonts: [
    { src: Poppins },
    { src: PoppinsMedium, fontWeight: 'medium' },
    { src: PoppinsBold, fontWeight: 'bold' },
  ],
});

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    fontFamily: 'Montserrat',
    fontSize: '8px',
    padding: '20 5',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    width: '100%',
    height: '100vh',
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textRow: {
    borderBottom: '1px solid #bfbbbb',
    marginTop: 3,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#bfbbbb',
  },
  rowTable: {
    flexDirection: 'row',
    borderBottomColor: '#bfbbbb',
    borderBottomWidth: 1,
    alignItems: 'center',
    textAlign: 'center',
    fontWeight: 400,
    flexGrow: 1,
  },

  bold: {
    fontWeight: 600,
  },
});

export const Potvrda = ({ intervention }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={[styles.row]}>
        <View
          style={{
            width: '100%',
            alignItems: 'flex-end',
          }}
        >
          <View
            style={{
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{
                marginRight: 50,
              }}
            >
              {`серијски бр. потврде: Р${intervention.vaccine.confirmationNo}`}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          marginTop: 50,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <View>
          <Image
            src={logo}
            style={{ width: 100, height: 60 }}
          />
        </View>
        <View
          style={{
            textAlign: 'center',
            width: '100%',
            marginTop: 5,
          }}
        >
          <Text>Република Србија</Text>
          <Text>Министарство пољопривреде, шумарства и водопривреде</Text>
          <Text>Управа за ветерину</Text>

          <Text style={[styles.bold, { marginTop: 10 }]}>Потврда о извршеном:</Text>
          <View
            style={[styles.row, {
              width: '40%',
              alignSelf: 'center',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 10,
            }]}
          >
            <View
              style={{
                border: '1px solid black',
                width: 14,
                height: 14,
              }}
            />
            <Text style={styles.bold}>
              обележавању и регистрацији паса / мачака
            </Text>
            <View />
          </View>
          <View
            style={[styles.row, {
              width: '40%',
              alignSelf: 'center',
              justifyContent: 'space-between',
              alignItems: 'center',
            }]}
          >
            <View
              style={{
                border: '1px solid black',
                width: 14,
                height: 14,
                textAlign: 'justify',
              }}
            />
            <Text style={styles.bold}>
              вакцинацији паса и мачака против заразних болести
            </Text>
            <View />
          </View>
        </View>
      </View>
      <View style={{
        marginTop: 5,
        borderTop: '1px solid black',
        width: '100%',
        padding: '2 4',
      }}
      >
        <Text style={[styles.bold, {}]}>
          1. Подаци о ветеринарској организацији:
        </Text>
        <View style={[styles.row, {
          justifyContent: 'flex-start',
          marginTop: 5,
        }]}
        >
          <Text style={{ width: '50%' }}>
            Назив: GAJ-VET DOO GAJ
          </Text>
          <Text style={{ width: '50%' }}>
            Адреса: Ul. Trg oslobodjenja br. 10, 25260 Gaj
          </Text>
        </View>
        <View style={[styles.row, {
          justifyContent: 'flex-start',
          marginTop: 5,
        }]}
        >
          <Text>
            Име и презиме ветеринара:
          </Text>
          <Text style={{
            marginLeft: 2,
            flex: 1,
            borderBottom: '1px dotted black',
          }}
          >
            {intervention.createdBy?.name}
          </Text>
        </View>
      </View>

      <View style={{
        marginTop: 5,
        borderTop: '1px solid black',
        width: '100%',
        padding: '2 4',
      }}
      >
        <Text style={[styles.bold, {}]}>
          2. Подаци о власнику, односно држаоцу пса или мачке:
        </Text>
        <View style={[styles.row, {
          justifyContent: 'flex-start',
          marginTop: 5,
        }]}
        >
          <View style={[styles.row, { width: '50%', paddingRight: 20 }]}>
            <Text>
              Име и презиме:
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {intervention.owner?.name}
            </Text>
          </View>
          <View style={[styles.row, { width: '50%' }]}>
            <Text>
              Адреса:
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {`${
                intervention.owner?.address
              }, ${
                intervention.owner?.zipCode
              } ${
                intervention.owner?.city
              }`}
            </Text>
          </View>
        </View>
        <View style={[styles.row, {
          justifyContent: 'flex-start',
          marginTop: 5,
        }]}
        >
          <View style={[styles.row, { height: 12, width: '50%', paddingRight: 20, alignItems: 'center' }]}>
            <Text>
              Телефон:
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {intervention.owner?.phoneNumber}
            </Text>
          </View>
          <View style={[styles.row, {}]}>
            <Text style={{ marginRight: 2, alignSelf: 'center' }}>
              ЈМБГ:
            </Text>
            {intervention.owner?.uniqueIdentificationNumber?.split('').map((uin, index) => (
              <View
                key={index}
                style={{
                  border: '1px solid black',
                  width: 12,
                  height: 12,
                  marginRight: 1,
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text>{uin}</Text>
              </View>
            ))}
          </View>
        </View>
      </View>

      <View style={{
        marginTop: 5,
        borderTop: '1px solid black',
        width: '100%',
        padding: '2 4',
      }}
      >
        <Text style={[styles.bold, {}]}>
          3. Подаци о псу / мачки ( заокружити ):
        </Text>
        <View style={[styles.row, {
          justifyContent: 'flex-start',
          marginTop: 5,
        }]}
        >
          <View style={[styles.row, { width: '33%', paddingRight: 20 }]}>
            <Text>
              Име:
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {intervention.animal?.name}
            </Text>
          </View>
          <View style={[styles.row, { width: '33%', paddingRight: 20 }]}>
            <Text>
              Раса:
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {intervention.animal?.breed}
            </Text>
          </View>
          <View style={[styles.row, { width: '33%', paddingRight: 20 }]}>
            <Text>
              Пол:
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {getTranslation(intervention.animal?.sex)}
            </Text>
          </View>
        </View>
        <View style={[styles.row, {
          justifyContent: 'flex-start',
          marginTop: 5,
        }]}
        >
          <View style={[styles.row, { width: '50%', paddingRight: 20 }]}>
            <Text>
              Датум рођења:
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {moment(intervention.animal?.dateOfBirth).format('DD.MM.YYYY')}
            </Text>
          </View>
          <View style={[styles.row, { width: '50%' }]}>
            <Text>
              Боја длаке:
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {intervention.animal?.color}
            </Text>
          </View>
        </View>
        <View style={[styles.row, {
          justifyContent: 'flex-start',
          marginTop: 5,
        }]}
        >
          <View style={[styles.row, { width: '50%', paddingRight: 20 }]}>
            <Text style={{ marginRight: 2, alignSelf: 'center' }}>
              Број микрочипа:
            </Text>
            {intervention.animal?.idNumber?.split('').map((uin, index) => (
              <View
                key={index}
                style={{
                  border: '1px solid black',
                  width: 12,
                  height: 12,
                  marginRight: 1,
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text>{uin}</Text>
              </View>
            ))}
          </View>
          <View style={[styles.row, { width: '50%', height: 12, alignItems: 'center' }]}>
            <Text>
              Датум обележавања:
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {moment(intervention.createdAt).format('DD.MM.YYYY')}
            </Text>
          </View>
        </View>
      </View>

      <View style={{
        marginTop: 5,
        borderTop: '1px solid black',
        width: '100%',
        padding: '2 4',
      }}
      >
        <Text style={[styles.bold, {}]}>
          4. Пасош за кућне љубимце:
        </Text>
        <View style={[styles.row, {
          justifyContent: 'flex-start',
          marginTop: 5,
        }]}
        >
          <View style={[styles.row, { width: '50%', paddingRight: 20 }]}>
            <Text>
              Број пасоша за кућне љубимце:
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {intervention.animal?.passportNumber}
            </Text>
          </View>
          <View style={[styles.row, { width: '50%' }]}>
            <Text>
              издат дана:
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {moment(intervention.animal?.passportIssueDate).format('DD.MM.YYYY')}
            </Text>
          </View>
        </View>
        <View style={[styles.row, {
          justifyContent: 'flex-start',
          marginTop: 5,
        }]}
        >
          <View style={[styles.row, { width: '100%' }]}>
            <Text>
              од стране (назив ветеринарске организације):
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {intervention.animal?.passportIssueAuthority}
            </Text>
          </View>
        </View>
      </View>

      <View style={{
        marginTop: 5,
        borderTop: '1px solid black',
        width: '100%',
        padding: '2 4',
      }}
      >
        <Text style={[styles.bold, {}]}>
          5. Вакцинација против заразних болести:
        </Text>
        <View style={[styles.row, {
          justifyContent: 'flex-start',
          marginTop: 5,
        }]}
        >
          <View style={[styles.row, { width: '33%', paddingRight: 20 }]}>
            <Text>
              Заразна болест:
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {intervention.vaccine?.illness}
            </Text>
          </View>
          <View style={[styles.row, { width: '33%', paddingRight: 20 }]}>
            <Text>
              Назив вакцине:
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {intervention.vaccine?.name}
            </Text>
          </View>
          <View style={[styles.row, { width: '33%' }]}>
            <Text>
              Серијски број вакцине:
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {intervention.vaccine?.serialNumber}
            </Text>
          </View>
        </View>
      </View>

      <View style={{
        marginTop: 5,
        borderTop: '1px solid black',
        width: '100%',
        padding: '2 4',
      }}
      >
        <Text style={[styles.bold, {}]}>
          6. Накнада:
        </Text>
        <View style={[styles.row, {
          justifyContent: 'flex-start',
          marginTop: 5,
        }]}
        >
          <View style={[styles.row, { width: '100%' }]}>
            <Text>
              Износ наплаћене накнаде за извршено обележавање и регистрацију кућног љубимца:
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {`${decimalize(intervention.vaccine?.priceChip || 0)} ${Types.CURRENCY}`}
            </Text>
          </View>
        </View>
      </View>

      <View style={{
        marginTop: 5,
        borderTop: '1px solid black',
        width: '100%',
        padding: '2 4',
      }}
      >
        <Text style={[styles.bold, {}]}>
          7. Наплаћена средства:
        </Text>
        <View style={[styles.row, {
          justifyContent: 'flex-start',
          marginTop: 5,
        }]}
        >
          <View style={[styles.row, { width: '100%' }]}>
            <Text>
              Износ наплаћених средстава за извршену вакцинацију пса / мачке:
            </Text>
            <Text style={{
              marginLeft: 2,
              flex: 1,
              borderBottom: '1px dotted black',
            }}
            >
              {`${decimalize(intervention.vaccine?.price || 0)} ${Types.CURRENCY}`}
            </Text>
          </View>
        </View>
      </View>

      <View style={[styles.row, {
        marginTop: 5,
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        width: '100%',
        padding: '2 4',
      }]}
      >
        <Text style={[styles.bold, {}]}>
          8. Укупно наплаћено:
        </Text>
        <Text style={{
          marginLeft: 2,
          flex: 1,
          borderBottom: '1px dotted black',
        }}
        >
          {`${decimalize(intervention.amount || 0)} ${Types.CURRENCY}`}
        </Text>
      </View>

      <View style={{
        width: '100%',
        padding: '0 40',
        marginTop: 50,
      }}
      >
        <View style={[styles.row, {
          justifyContent: 'space-between',
          marginTop: 5,
          textAlign: 'center',
        }]}
        >
          <Text
            style={{
              width: '33%',
              borderTop: '1px dotted black',
            }}
          >
            Потпис власника / држаоца животиње
          </Text>
          <Text
            style={{
              width: '33%',
            }}
          >
            М.П.
          </Text>
          <Text
            style={{
              width: '33%',
              borderTop: '1px dotted black',
            }}
          >
            Рег. број и потпис ветеринара
          </Text>
        </View>
        <View style={[styles.row, {

          marginTop: 30,
          textAlign: 'center',
        }]}
        >
          <Text
            style={{
              width: '33%',
              borderTop: '1px dotted black',
            }}
          >
            Датум
          </Text>
        </View>
      </View>

    </Page>
  </Document>
);

const DownloadPotvrda = ({
  children,
  fileName,
  ...props
}) => (
  <>
    <PDFDownloadLink
      document={<Potvrda {...props} />}
      fileName={fileName || 'profaktura.pdf'}
    >
      {children}
    </PDFDownloadLink>
  </>
);

export default DownloadPotvrda;
