import React from 'react';

import Icon from 'antd/lib/icon';
import Button from 'antd/lib/button';

import Admin from 'hive-admin';
import { Link } from 'hive-admin/node_modules/react-router-dom';

import {
  getLinkWithSearchFields,
} from '../../components/Links';
import SvgCat from '../../components/SvgCat';

import FILTERS from './filters';
import FIELDS from './fields';
import COLUMNS from './columns';

import Types from '../../modules/types';

import { ACTIONS } from '../common';

const { helpers: { getTranslation } } = Types;

const CatIcon = () => <Icon component={SvgCat} />;

export default Admin.compileFromLibrary(['GroupResource', {
  title: getTranslation('pets'),
  icon: CatIcon,
  path: '/animals',
  redirect: [['redirect.unauthorized']],
  archiveConfig: {
    filters: FILTERS,
    columns: COLUMNS,
    createButtonLabel: getTranslation('create_new_item'),
    icon: CatIcon,
    title: getTranslation('pets'),
    label: getTranslation('pets'),
  },
  singleConfig: {
    hidden: true,
    alias: '/animals',
    backButtonPaths: ['/animals'],
    backButtonLabel: getTranslation('close'),
    headerTitle: getTranslation('create'),
  },
  singleCreateConfig: {
    title: getTranslation('pets'),
    actions: [ACTIONS.CREATE],
  },
  singleEditConfig: {
    title: getTranslation('pets'),
    actions: [
      ACTIONS.SAVE,
      ACTIONS.DELETE_ADMIN,
      [() => ({
        IS_ACTION: true,
        section: 'bottom',
        render: props => (
          <Button type="primary" key={`${Math.random()}`.slice(2)}>
            <Link
              to={getLinkWithSearchFields(
                null,
                [{
                  name: 'animal',
                  value: props.data._id,
                  replace: true,
                }],
                { pathname: '/interventions' },
              )}
            >
              {getTranslation('see_interventions')}
            </Link>
          </Button>
        ),
      }), {}],
      [() => ({
        IS_ACTION: true,
        section: 'bottom',
        render: props => (
          <Button type="primary" key={`${Math.random()}`.slice(2)}>
            <Link
              to={getLinkWithSearchFields(
                null,
                [{
                  name: 'animal',
                  value: props.data._id,
                  replace: true,
                }, {
                  name: 'owner',
                  value: props.data.owner,
                  replace: true,
                }],
                { pathname: '/interventions/create' },
              )}
            >
              {getTranslation('create_intervention')}
            </Link>
          </Button>
        ),
      }), {}],
    ],
  },
  fields: FIELDS,
}]).pages;
